import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './ElectricFenceSolutionsPost.css';
import electricFenceImage from '../../Assets/images/electricFence.png';

const ElectricFenceSolutions = () => {
  return (
    <div className="electricFenceSolutions">
      <div className="electricFenceSolutionsCard">
        <div className="electricFenceImageContainer">
          <img src={electricFenceImage} alt="Electric Fence Solutions" className="electricFenceImage" />
        </div>
        <div className="electricFenceContent">
          <h2>
            Electric Fence <span className="highlighted-textelectric"> Solutions</span>
          </h2>
          <p>
            Our layered infrastructure security plans encompass fixed and roving posts, checkpoints, access control, and technical security systems. We analyze client vulnerabilities and follow up with recommendations ranging from installing security equipment and deploying security personnel to the development of information security programs.
          </p>
          <p>
            Our infrastructure security services include:
          </p>
          <ul>
            <li><FaCheckCircle className="check-icon" /> Perimeter protection measures such as electric fencing and walls</li>
            <li><FaCheckCircle className="check-icon" /> Barriers (turnstiles) to assist with access control and vehicle barriers</li>
            <li><FaCheckCircle className="check-icon" /> Gates</li>
            <li><FaCheckCircle className="check-icon" /> Safe room construction</li>
            <li><FaCheckCircle className="check-icon" /> Blast proofing and blast proof film installation for exposed windows</li>
            <li><FaCheckCircle className="check-icon" /> Traffic flow management</li>
          </ul>
          <button className="contactButton">
              <Link to="/book-survey">Book Survey</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ElectricFenceSolutions;
