import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import './WhoWeAreSection.css';
import securityImage from '../../Assets/images/RSSCar.png'; // Adjust path as necessary

const WhoWeAreSection = () => {
  return (
    <section className="wwa-who-we-are-about-section">
      <div className="wwa-section-about-card">
        <div className="wwa-content-container">
          <div className="wwa-card-content">
          <h2 className="wwa-section-about-title">
            Who <span className="wwa-highlighted-text">We Are</span>
          </h2>
            <p className="wwa-description">
              REPAY SECURITY SERVICES LTD is one of the leading security service providers in Kenya. We provide a world-class service to the manufacturing industrial, learning institutions, shopping malls, government institutions, and non-profit institutions, commercial and domestic properties by providing reliable services. To provide top-quality Services Solutions that exceed the expectations of our esteemed Clients. Our mission is to build long-term relationships with our clients and provide exceptional Supplies and Services Solutions through innovation and advanced technology to deliver Superior Solutions for our Client.
            </p>
            <div className="wwa-badge wwa-blinking-badge1">
              <h3>IN EVERY ENGAGEMENT, WE BRING IN:</h3>
              <ul>
                <li><FaCheckCircle className="wwa-check-icon1" /> Unmatched loyalty</li>
                <li><FaCheckCircle className="wwa-check-icon1" /> Unsullied integrity</li>
                <li><FaCheckCircle className="wwa-check-icon1" /> Indubitable technical competence</li>
              </ul>
            </div>
            <a href="/about-rss" className="wwa-read-more-about-button" aria-label="Read more about RSS">Read More</a>
          </div>
        </div>
        <div className="wwa-image-container">
          <img src={securityImage} alt="Security Company" className="wwa-card-image" />
        </div>
      </div>
    </section>
  );
};

export default WhoWeAreSection;
