import React, { useState } from 'react';
import axios from 'axios';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import './ContactForm.css'; // Import the CSS file

const ContactForm = () => {
    const [formData, setFormData] = useState({
        fullname: '',
        phone: '',
        email: '',
        location: '',
        message: '',
    });

    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://api.repaysecurity.co.ke/send-email', formData);
            setSuccessMessage('Submitted successfully!');
            setFormData({
                fullname: '',
                phone: '',
                email: '',
                location: '',
                message: '',
            });
        } catch (error) {
            console.error('Error sending email:', error);
            setSuccessMessage('Error sending email. Please try again later.');
        }
    };

    return (
        <div className="contact-us-container">
            <div className="contact-us-header">
            <h2 className="company-name-title">
                Get in <span className="aboutUs-highlighted-text">Touch</span>
            </h2>
                <p>We'd love to hear from you! Whether you have a question about our services or need assistance, our team is ready to help.</p>
            </div>
            <div className="contact-us-details">
                <div className="contact-us-info">
                    <h2>
                    Contact <span className="aboutUs-highlighted-text">Information</span>
                    </h2>
                    <ul>
                        <li>Address: LAYCANON Nairobi, 00100 Kenya</li>
                        <li>Phone: 0711166611 (Daily 8am - 8pm)</li>
                        <li>Email: info@repaysecurity.co.ke</li>
                    </ul>
                </div>
                <div className="contact-us-form">
                    <h2>
                    Contact <span className="aboutUs-highlighted-text">Form</span>
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="fullname">Full Name/Organization Name:</label>
                            <input type="text" id="fullname" name="fullname" value={formData.fullname} onChange={handleChange} required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone:</label>
                            <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="location">Location:</label>
                            <input type="text" id="location" name="location" value={formData.location} onChange={handleChange} required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message:</label>
                            <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
                        </div>
                        <button type="submit" className="submit-button">Submit</button>
                    </form>
                    {successMessage && <p className="success-message">{successMessage}</p>}
                </div>
            </div>
            <div className="contact-us-social">
                <h2>
                Follow <span className="aboutUs-highlighted-text">Us</span>
                    </h2>
                <div className="social-icons">
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
                    <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
