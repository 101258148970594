// ServiceSection.jsx
import React from 'react';
import ServiceCard from './ServiceCard';
import './ServiceSection.css';
import guardingImage from './../../Assets/images/securityPersonell.png';
import securityDogsImage from './../../Assets/images/SecurityDog.png';
import specializedServicesImage from './../../Assets/images/SecurityGuardWorkplace.png';
import electricFenceImage from './../../Assets/images/electricFence.png';
import alarmMonitoringImage from './../../Assets/images/alarm-monitoring-small.jpg';
import accessControlImage from './../../Assets/images/Access control.jpg';
import cctvSolutionsImage from './../../Assets/images/CCTV-Security.png';
import bollardsImage from './../../Assets/images/Boom-Barrier.jpg';
import supportImage from './../../Assets/images/customercare.png';
import personnelImage from './../../Assets/images/securityguard.png';

const services = [
  {
    title: 'Guarding',
    description: 'Our professional security guards are extensively trained to provide protection and ensure safety in various environments, ranging from residential properties to commercial establishments. With a keen eye for detail and excellent communication skills, our guarding services offer peace of mind to our clients.',
    imageUrl: guardingImage,
    link: '/services/physical-security', // Add the link for Guarding service
  },
  {
    title: 'Security Dogs',
    description: 'Our highly trained security dogs offer an additional layer of protection against intruders and unauthorized access. With their keen sense of smell and ability to detect suspicious activities, our security dogs effectively deter potential threats and provide a sense of security to our clients.',
    imageUrl: securityDogsImage,
    link: '/services/security-dogs', // Add the link for Security Dogs service
  },
  {
    title: 'Specialized Services',
    description: 'Our specialized security services are tailored to meet the unique needs and requirements of our clients. From VIP protection to event security, our team of experts delivers personalized solutions that prioritize safety and ensure a secure environment for all.',
    imageUrl: specializedServicesImage,
    link: '/services/specialized-services', // Add the link for Specialized Services
  },
  {
    title: 'Electric Fence Solutions',
    description: 'Our electric fence solutions offer a robust and effective deterrent against unauthorized access and intrusion. With advanced technology and expert installation, our electric fences provide reliable security measures to protect properties and assets.',
    imageUrl: electricFenceImage,
    link:'/services/electric-fence-solutions', // Add the link for Electric Fence Solutions
  },
  {
    title: 'Alarm Monitoring & Response',
    description: 'Our alarm monitoring and response services offer round-the-clock surveillance and immediate response to any security breaches or alarms triggered on our clients\' premises. With our rapid response teams and state-of-the-art monitoring systems, we ensure timely intervention and resolution of security incidents.',
    imageUrl: alarmMonitoringImage,
    link:'/services/alarm-monitoring-response', // Add the link for Alarm Monitoring & Response
  },
  {
    title: 'Access Control',
    description: 'Our access control systems enable organizations to restrict and manage access to their premises, ensuring only authorized individuals can enter designated areas. With features such as biometric authentication and centralized management, our access control solutions provide comprehensive security measures for businesses and institutions.',
    imageUrl: accessControlImage,
    link: '/services/access-control', // Add the link for Access Control
  },
  {
    title: 'CCTV Solutions',
    description: 'Our CCTV solutions offer 24/7 surveillance onsite and offsite, providing real-time monitoring and recording of activities within our clients\' premises. With high-definition cameras and advanced analytics, our CCTV systems enhance security and provide valuable insights for risk management and incident investigation.',
    imageUrl: cctvSolutionsImage,
    link: '/cctv-solutions', // Add the link for CCTV Solutions
  },
  {
    title: 'Bollards/Turnstiles/Barriers',
    description: 'Our bollards, turnstiles, and barriers offer physical security measures to control access and secure perimeters. From vehicle barriers to pedestrian turnstiles, our solutions are designed to withstand unauthorized entry and protect critical infrastructure and assets.',
    imageUrl: bollardsImage,
    link: '/services/bollards-turnstiles-barriers', // Add the link for Bollards/Turnstiles/Barriers
  },
  // {
  //   title: '24hrs Support & Customer Service Center',
  //   description: 'Our 24-hour support and customer service center ensure continuous assistance and support to our clients whenever they need it. With dedicated staff and advanced communication systems, we provide prompt response and resolution to inquiries, concerns, and emergencies.',
  //   imageUrl: supportImage,
  //   link: '/support-customer-service', // Add the link for 24hrs Support & Customer Service Center
  // },
  // {
  //   title: 'Professional & Equipped Personnel',
  //   description: 'Our security personnel are rigorously trained and equipped with the necessary skills and tools to handle various security challenges effectively. From company uniform to personal protective equipment, our personnel are prepared to fulfill their roles with professionalism and commitment.',
  //   imageUrl: personnelImage,
  //   link: '/professional-equipped-personnel', // Add the link for Professional & Equipped Personnel
  // },
];

const ServiceSection = () => {
  return (
    <div className="service-section">
      <h2 className="section-title">
            Our <span className="wwa-highlighted-text">Services</span>
      </h2>
      <div className="service-grid">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            title={service.title}
            description={service.description}
            imageUrl={service.imageUrl}
            link={service.link}
          />
        ))}
      </div>
    </div>
  );
};

export default ServiceSection;
