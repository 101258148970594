import React from 'react';
import './CCTVInstallation.css';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import cctvImage from '../../Assets/images/cctvs.jpg';

const CCTVInstallation = () => {
  return (
    <div className="cctvInstallation">
      <div className="cctvInstallationCard">
        <div className="cctvImageContainer">
          <img src={cctvImage} alt="CCTV-Solutions" className="cctvInstallationImage" />
        </div>
        <div className="cctvInstallationContent">
          <h2>
            CCTV Installation & <br>
            </br><span className="aboutUs-highlighted-text">Remote Monitoring</span>
          </h2>
          <p>
            At REPAY SECURITY SERVICES LTD, we believe that remote monitoring is the heart of an effective and cost-efficient security system. Without this service, security systems become passive and ineffective. Many companies simply use CCTV cameras for recording incidents as and when they happen.
          </p>
          <p>
            We provide an extensive range of CCTV monitoring systems that can be tailored to meet the individual needs of your business. We offer a choice of traditional analogue CCTV systems or the latest IP-based digital solutions with everything from stand-alone cameras for a branch office or building complexes to integrated and IP addressable CCTV systems for high-security environments.
          </p>
          <p>
            Our state-of-the-art monitoring systems allow us to effectively and remotely monitor and respond to any incidents that may occur, day or night. Some of the features include but are not limited to:
          </p>
          <ul>
            <li><FaCheckCircle className="check-icon" /> Choice of dome, bullet or box cameras</li>
            <li><FaCheckCircle className="check-icon" /> Day/night video technology</li>
            <li><FaCheckCircle className="check-icon" /> Infrared illumination</li>
            <li><FaCheckCircle className="check-icon" /> Wide range of digital video recorders</li>
            <li><FaCheckCircle className="check-icon" /> Touchscreen monitor surveillance available</li>
            <li><FaCheckCircle className="check-icon" /> Mobile connectivity for remote detection</li>
          </ul>
          <p>
            By utilizing our Security remote monitoring services, a CCTV system can quickly become a powerful and pro-active element in your security. Because we create bespoke solutions for clients we’re also able to tailor unique alarm responses to each individual site in order to meet your exact requirements. Our main focus is the monitoring of detection activated systems including CCTV, fire alarms and intruder alarms.
          </p>
          <p>
            Our operators are trained to distinguish between serious incidents and something which is of little significance it means that the police are called in only if it is necessary, which eliminates the problems which can be caused by the false alarms of other security and warning systems.
          </p>
          <p>
            24/7 through our extensive CCTV (closed-circuit television) system. Watched by our field supervisors and highly-trained command center operators, our command center provides an additional layer of security and supervision for your property.
          </p>
           <button className="bookSurveyButton">
              <Link to="/book-survey">Book Survey</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CCTVInstallation;
