import React, { useState, useEffect } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [submenuType, setSubmenuType] = useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const toggleSubmenu = (type) => {
    if (submenuType === type) {
      setShowSubmenu(!showSubmenu);
    } else {
      setShowSubmenu(true);
      setSubmenuType(type);
    }
  };

  useEffect(() => {
    const yearInterval = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000);

    return () => clearInterval(yearInterval);
  }, []);

  return (
    <footer className="footer">
      <div className="footer-section">
      <a href="/" className="home-link">
          <h4 className="footerTitle">
          Repay Security <span className="footer-highlighted-text1">Services Ltd</span>
          </h4>
          <p>Your Safety, Our Priority</p>
        </a>
      </div>

      <div className="footer-sectionLinks">
        <h4>Quick Links</h4>
        <ul>
          <li><a href="/services/physical-security" style={{ textDecoration: 'none', color: '#fff' }}>Guarding</a></li>
          <li><a href="/services/security-dogs" style={{ textDecoration: 'none', color: '#fff' }}>Security Dogs</a></li>
          <li><a href="/services/specialized-services" style={{ textDecoration: 'none', color: '#fff' }}>Specialized Services</a></li>
          <li><a href="/services/electric-fence-solutions" style={{ textDecoration: 'none', color: '#fff' }}>Electric Fence Solutions</a></li>
          <li><a href="/services/alam-monitoring-responser" style={{ textDecoration: 'none', color: '#fff' }}>Alarm Monitoring & Response</a></li>
          <li><a href="/services/access-control" style={{ textDecoration: 'none', color: '#fff' }}>Access Controls</a></li>
          <li><a href="/services/cctv-solutions" style={{ textDecoration: 'none', color: '#fff' }}>CCTV Solutions</a></li>
          <li><a href="/services/bollards-turnstiles-barriers" style={{ textDecoration: 'none', color: '#fff' }}>Bollards/ Turnstiles/Barriers</a></li>
          {/* <li><a href="/services/customer-service-center" style={{ textDecoration: 'none', color: '#fff' }}>24hrs Support & Customer Service Center</a></li> */}
          {/* <li><a href="/services/professional-personnel" style={{ textDecoration: 'none', color: '#fff' }}>Professional & Equipped Personnel</a></li> */}
        </ul>
      </div>

      <div className="footerContact-us-section">
        <h4>Contact Us</h4>
        <ul>
          <li>LAYCANON Nairobi, 00100 Kenya</li>
          <li>0711166611 (Daily 8am - 8pm)</li>
          <li>info@repaysecurity.co.ke</li>
        </ul>

        <div className="social-icons1">
          <a href="#" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
          <a href="#" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
          <a href="#" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
          <a href="#" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
        </div>
    
        <div className="bottom-line" id="current-year">
          © {currentYear} Repay Security Services Ltd. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
