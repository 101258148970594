import React, { useState } from 'react';
import { menuItems } from '../NavbarSection/MenuItems';
import './Sidebar.css';

const Sidebar = ({ isOpen, onCloseSidebar }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleSubmenuToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="close-sidebar" onClick={onCloseSidebar}>
        &times;
      </div>
      <ul className="menu2">
        {menuItems.map((menuItems, index) => (
          <li key={index} className="menu-item2">
            <a href={menuItems.url}>{menuItems.title}</a>
            {menuItems.submenuItems.length > 0 && (
              <>
                <button
                  className={`submenu2-toggle2 ${activeIndex === index ? 'active' : ''}`}
                  onClick={() => handleSubmenuToggle(index)}
                >
                  {activeIndex === index ? '-' : '+'}
                </button>
                <ul className={`submenu2 ${activeIndex === index ? 'open' : ''}`}>
                  {menuItems.submenuItems.map((submenuItems, subIndex) => (
                    <li key={subIndex} className="submenu2-item">
                      <a href={submenuItems.url}>{submenuItems.title}</a>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
