// ServiceCard.jsx
import React from 'react';
import './ServiceCard.css';

const ServiceCard = ({ title, description, imageUrl, link }) => {
  return (
    <div className="service-card">
      <img src={imageUrl} alt={title} className="service-image" />
      <div className="service-content">
        <h3 className="service-title">{title}</h3>
        <p className="service-description">{description}</p>
        <a href={link} className="read-more-button">Read More</a>
      </div>
    </div>
  );
};

export default ServiceCard;
