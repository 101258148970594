import React, { useState } from 'react';
import axios from 'axios';
import './ServiceRequestForm.css';

const ServiceRequestForm = () => {
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        phone: '',
        service: '',
        location: '',
        specificRequirements: '',
        hearAboutUs: '',
    });

    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://api1.repaysecurity.co.ke/send-email', formData);
            setSuccessMessage('Submitted successfully!');
            setFormData({
                fullname: '',
                email: '',
                phone: '',
                service: '',
                location: '',
                specificRequirements: '',
                hearAboutUs: '',
            });
        } catch (error) {
            console.error('Error sending email:', error);
            setSuccessMessage('Error sending email. Please try again later.');
        }
    };

    return (
        <div className="service-request-container">
            <div className="service-request-header">
                <h1>
                    Service Request <span className="highlighted-textelectric"> Form</span>
                </h1>
                <p>Please fill out the form below to book for a survey/ appointment.</p>
            </div>
            <div className="service-request-form">
                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="fullname">Full Name/Organization Name:</label>
                            <input type="text" id="fullname" name="fullname" value={formData.fullname} onChange={handleChange} required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="phone">Phone:</label>
                            <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="service">Choose a Service:</label>
                            <select id="service" name="service" value={formData.service} onChange={handleChange} required>
                                <option value="" disabled>Select a service</option>
                                <option value="Guarding">Guarding</option>
                                <option value="Security Dogs">Security Dogs</option>
                                <option value="Specialized Services">Specialized Services</option>
                                <option value="Electric Fence Solutions">Electric Fence Solutions</option>
                                <option value="Alarm Monitoring & Response">Alarm Monitoring & Response</option>
                                <option value="Access Control">Access Control</option>
                                <option value="CCTV Solutions">CCTV Solutions</option>
                                <option value="Bollards/Turnstiles/Barriers">Bollards/Turnstiles/Barriers</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="location">Location:</label>
                            <input type="text" id="location" name="location" placeholder="City, area, building etc." value={formData.location} onChange={handleChange} required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="hearAboutUs">How did you hear about us?</label>
                            <select id="hearAboutUs" name="hearAboutUs" value={formData.hearAboutUs} onChange={handleChange} required>
                                <option value="" disabled>Select an option</option>
                                <option value="Friends">Friends</option>
                                <option value="Security officers">Security officers</option>
                                <option value="Social media">Social media</option>
                                <option value="Website">Website</option>
                                <option value="Other platforms">Other platforms</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="specificRequirements">Specific Requirements/Questions:</label>
                        <textarea id="specificRequirements" name="specificRequirements" value={formData.specificRequirements} onChange={handleChange} required></textarea>
                    </div>
                    <div className="button-container">
                        <button type="submit" className="submit-button">Submit</button>
                    </div>
                </form>
                {successMessage && <p className="success-message">{successMessage}</p>}
            </div>
        </div>
    );
};

export default ServiceRequestForm;
