import React, { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import './BollardsTurnstilesBarriers.css';

// Import images for each service
import automaticGateImage from '../../Assets/images/automaticGates.png';
import vehicleBarrierImage from '../../Assets/images/Boom-Barrier.jpg';
import metalDetectorImage from '../../Assets/images/Access control.jpg';

const BollardsTurnstilesBarriers = () => {
  const [expandedService, setExpandedService] = useState('');

  // Function to toggle service details
  const toggleService = (service) => {
    setExpandedService(expandedService === service ? '' : service);
  };

  return (
    <div className="bollardsTurnstilesBarriersSection">
      <h2 className="bollardsTurnstilesBarriersTitle">Bollards, Turnstiles & Barriers</h2>
      <div className="bollardsTurnstilesBarriersDescription">
        <p>
          RSS Security offers a comprehensive range of access control solutions including automatic gates, vehicle barriers, and metal detectors. Our systems are designed to provide maximum security, control, and convenience for residential, commercial, and industrial applications.
        </p>
      </div>

      {/* Automatic Gate */}
      <div className="bollardsService">
        <div className="serviceHeader" onClick={() => toggleService('Automatic Gate')}>
          <h3 className="serviceTitle">Automatic Gate</h3>
          <button className="expandButton">
            {expandedService === 'Automatic Gate' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Automatic Gate' && (
          <div className="serviceDetails active">
            <img src={automaticGateImage} alt="Automatic Gate" className="serviceImage" />
            <p>
              RSS Security offers strong and sturdy remote-controlled sliding and electronic swing gates for access control to homes, apartment blocks, and commercial facilities, among other applications. Our gates are designed for superior strength and security, suitable for both commercial and industrial use.
            </p>
          </div>
        )}
      </div>

      {/* Vehicle Barrier */}
      <div className="bollardsService">
        <div className="serviceHeader" onClick={() => toggleService('Vehicle Barrier')}>
          <h3 className="serviceTitle">Vehicle Barrier</h3>
          <button className="expandButton">
            {expandedService === 'Vehicle Barrier' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Vehicle Barrier' && (
          <div className="serviceDetails active">
            <img src={vehicleBarrierImage} alt="Vehicle Barrier" className="serviceImage" />
            <p>
              Boom (Barrier) gates are typically used for car parks, apartment complexes, and tollbooths to control the flow of traffic. At RSS Security, we offer both manual and automatic boom gates and barriers for vehicle and pedestrian access control, high security, and anti-terror security measures.
              <br />
              <br />
              Boom (barrier) gates are the ideal way to automate and control vehicle movement in:
              <ul>
                <li><FaCheckCircle className="check-icon" /> Public parking entrances</li>
                <li><FaCheckCircle className="check-icon" /> Car parks</li>
                <li><FaCheckCircle className="check-icon" /> Private entrances</li>
                <li><FaCheckCircle className="check-icon" /> Entrances to a block of flats/units</li>
              </ul>
            </p>
          </div>
        )}
      </div>

      {/* Metal Detectors */}
      <div className="bollardsService">
        <div className="serviceHeader" onClick={() => toggleService('Metal Detectors')}>
          <h3 className="serviceTitle">Metal Detectors</h3>
          <button className="expandButton">
            {expandedService === 'Metal Detectors' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Metal Detectors' && (
          <div className="serviceDetails active">
            <img src={metalDetectorImage} alt="Metal Detectors" className="serviceImage" />
            <p>
              RSS Security’s partnerships have allowed us to provide the East African market with high-performance walk-through metal detectors that meet all international security standards. These walk-through detectors allow a high level of differentiation between significant metal masses, such as weapons and personal metal effects.
              <br />
              <br />
              The features include but are not limited to:
              <ul>
                <li><FaCheckCircle className="check-icon" /> Accurate detection of magnetic, non-magnetic, and mixed-alloy metal weapons</li>
                <li><FaCheckCircle className="check-icon" /> High differentiation and throughput</li>
                <li><FaCheckCircle className="check-icon" /> Exceptional immunity to environmental interference</li>
                <li><FaCheckCircle className="check-icon" /> Unmatched reliability</li>
                <li><FaCheckCircle className="check-icon" /> Rapid installation</li>
              </ul>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BollardsTurnstilesBarriers;
