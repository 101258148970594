import React from 'react';
import './ContactBar.css';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';

const ContactBar = () => {
  // const handleEmergencyButtonClick = () => {
  //   window.location.href = 'tel:+254707777525'; 
  // };

  return (
    <div className="contactBar">
      <div className="contact-info">
        <span className="phone">
          <FaPhone /> 0711166611
        </span>
        <span className="email">
          <FaEnvelope /> info@repaysecurity.co.ke
        </span>
      </div>
      {/* <button className="emergencyButton" onClick={handleEmergencyButtonClick}>
        Call for Emergency
      </button> */}
      <div className="socialIcons">
        <a href="#" className="social-icon1"><FaFacebookF /></a>
        <a href="#" className="social-icon1"><FaTwitter /></a>
        <a href="#" className="social-icon1"><FaLinkedinIn /></a>
      </div>
    </div>
  );
};

export default ContactBar;
