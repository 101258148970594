import React from 'react';
import { Link } from 'react-router-dom';
import './AccessControl.css';
import { FaCheckCircle } from 'react-icons/fa';
import accessControlImage from '../../Assets/images/AccessControl.png';

const AccessControl = () => {
  return (
    <div className="accessControl">
      <div className="accessControlCard">
        <div className="accessControlImageContainer">
          <img src={accessControlImage} alt="Access Control Solutions" className="accessControlImage" />
        </div>
        <div className="accessControlContent">
          <h2>
            Access Control <br />
            <span className="aboutUs-highlighted-text">Systems</span>
          </h2>
          <p>
            RSS Security offers reliable, durable, and highly accurate biometric access systems. They come already assembled and easy to program via keypads and embedded firmware. Whether they are used to access a building, secure restricted locations within establishments, or for time and attendance management, our biometric access systems enhance building security, internal communications, and employee management with utmost reliability and accuracy.
          </p>
          <p>Some of the features include:</p>
          <ul>
            <li><FaCheckCircle className="check-icon" /> Reliable, durable, and highly accurate infrared detection biometric sensor</li>
            <li><FaCheckCircle className="check-icon" /> User recognition in less than one second</li>
            <li><FaCheckCircle className="check-icon" /> Stores 1,500 fingerprint templates and 30,000 transactions</li>
            <li><FaCheckCircle className="check-icon" /> Reads fingerprint and/or RFID cards</li>
            <li><FaCheckCircle className="check-icon" /> Built-in serial, Ethernet, and USB ports</li>
            <li><FaCheckCircle className="check-icon" /> Tamper switch and alarm contacts</li>
            <li><FaCheckCircle className="check-icon" /> And more</li>
          </ul>
          <button className="bookDemoButton">
            <Link to="/book-survey">Book Survey</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccessControl;
