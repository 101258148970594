export const menuItems = [
    {
      title: 'Home',
      url: '/',
      submenuItems: [],
    },
    {
      title: 'About Us',
      url: '/about-rss',
      submenuItems: [],
    },
    {
      title: 'Services',
      submenuItems: [
        {
          title: 'Guarding',
          url: '/services/physical-security',
        },
        {
          title: 'Security Dogs',
          url: '/services/security-dogs',
        },
        {
          title: 'Specialized Services',
          url: '/services/specialized-services',
        },
        {
          title: 'Electric Fence Solutions',
          url: '/services/electric-fence-solutions',
        },
        {
          title: 'Alarm Monitoring & Response',
          url: '/services/alarm-monitoring-response',
        },
        {
          title: 'Access Control',
          url: '/services/access-control',
        },
        {
          title: 'CCTV Solutions',
          url: '/services/cctv-solutions',
        },
        {
          title: 'Bollards/Turnstiles/Barriers',
          url: '/services/bollards-turnstiles-barriers',
        },
        // {
        //   title: '24hrs Support & Customer Service Center',
        //   url: '/services/customer-service-center',
        // },
        // {
        //   title: 'Professional & Equipped Personnel',
        //   url: '/services/professional-personnel',
        // },
        // Add more services here as needed
      ],
    },
    {
      title: 'Contact Us',
      url: '/contact-us',
      submenuItems: [],
    },
  ];
  