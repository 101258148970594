import React from 'react';
import { Link } from 'react-router-dom';
import './AboutUsPage.css';
import companyImage from '../../../Assets/images/securityguard.png';
import WhyUs from '../WhyUsSection/WhyUs';

const AboutUsPage = () => {
  return (
    <div className="aboutUsPage">
      <div className="aboutUs-card">
        <div className="aboutUs-content-container">
          <div className="aboutUs-card-content">
            <h2 className="section-aboutUs-title">
              Who <span className="aboutUs-highlighted-text">We Are</span>
            </h2>
            <p className="aboutUs-description">
              RSS was founded and incorporated according to the Companies Act Cap 486 laws of Kenya in March 2024 and has been operational since then. With directors and key employees collectively possessing over 10 years of experience, we offer more than just security guards. We conduct security audits, prioritizing a secure and tranquil environment for our clients, while upholding world-class professionalism, customer care, and competence.
              <br />
              <br />
              Our security models are customer-focused and versatile, designed with individual client requirements in mind. Security is our business, and our clients' comfort and peace of mind are our top priorities. Our tech-savvy team is dedicated to devising cutting-edge methods to deter, detect, and record potential criminal activity.
              <br />
              <br />
              We promise value to our clients and adhere to our customer charter, founded on pillars of professionalism, customer care, and competence.
            </p>
            <button className="help-aboutUs-button">
              <Link to="/book-survey">How can we help you</Link>
            </button>
          </div>
        </div>
        <div className="aboutUs-image-container">
          <img src={companyImage} alt="Company" className="aboutUs-card-image" />
        </div>
      </div>
      <WhyUs />
    </div>
  );
};

export default AboutUsPage;
