// WhyUs.jsx
import React, { useState, useEffect } from 'react';
import './WhyUs.css';
import { FaMedal, FaGlobe } from 'react-icons/fa';
import { SiSpringsecurity } from "react-icons/si";
import { MdOutlineGroupAdd } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { GrUserPolice } from "react-icons/gr";
import { BiSupport } from "react-icons/bi";
import { GiShakingHands } from "react-icons/gi";
import { FaHandsHoldingChild } from "react-icons/fa6";

const WhyUs = () => {
  const [clientsCount, setClientsCount] = useState(0);
  const [guardsCount, setGuardsCount] = useState(0);

  useEffect(() => {
    // Simulate fetching data from API
    setTimeout(() => {
      setClientsCount(100);
      setGuardsCount(1000);
    }, 100);
  }, []);

  return (
    <div className="whyUsSection">
      <h2 className="whyUsTitle">
            Why <span className="wwa-highlighted-text">Choose Us</span>
          </h2>
      <div className="whyUsCards">
        <div className="whyUsCard">
          <SiSpringsecurity  className="whyUsIcon" />
          <h3>Secure Solutions</h3>
          <p>We provide comprehensive security solutions tailored to your needs.</p>
        </div>
        <div className="whyUsCard">
          <FaPeopleGroup className="whyUsIcon" />
          <h3>Professional Team</h3>
          <p>Our team consists of over {guardsCount} highly-trained security professionals.</p>
        </div>
        <div className="whyUsCard">
          <FaHandsHoldingChild className="whyUsIcon" />
          <h3>Fully Insured</h3>
          <p>Our services are fully insured to provide you with peace of mind.</p>
        </div>
        <div className="whyUsCard">
          <BiSupport className="whyUsIcon" />
          <h3>24/7 Support</h3>
          <p>Get support anytime, anywhere with our 24/7 customer service.</p>
        </div>
        {/* <div className="whyUsCard">
          <FaMedal className="whyUsIcon" />
          <h3>ISO Quality Assurance</h3>
          <p>We maintain ISO quality standards to ensure the highest level of service.</p>
        </div>
        <div className="whyUsCard">
          <FaGlobe className="whyUsIcon" />
          <h3>Kenya Security Industry Association</h3>
          <p>Proud member of the Kenya Security Industry Association.</p>
        </div> */}
        <div className="whyUsCard">
          <GrUserPolice className="whyUsIcon" />
          <h3>Equipped Personnel</h3>
          <p>Our personnel are equipped with the latest tools and technology.</p>
        </div>
      </div>

      <div className="coreValuesSection">
        <h2 className="coreValuesTitle">
            Our Core <span className="wwa-highlighted-text">Values</span>
          </h2>
        <div className="coreValuesCards">
          <div className="coreValueCard">
            <FaMedal className="coreValueIcon" />
            <h3>Commitment</h3>
            <p>We are committed to providing the highest level of security services.</p>
          </div>
          <div className="coreValueCard">
            <GiShakingHands className="coreValueIcon" />
            <h3>Customer Value</h3>
            <p>Customer satisfaction and value creation are at the heart of our business.</p>
          </div>
          <div className="coreValueCard">
            <MdOutlineGroupAdd  className="coreValueIcon" />
            <h3>Teamwork</h3>
            <p>We work collaboratively to achieve our goals and exceed expectations.</p>
          </div>
          <div className="coreValueCard">
            <FaGlobe className="coreValueIcon" />
            <h3>Global Presence</h3>
            <p>We operate globally to serve our clients wherever they need us.</p>
          </div>
          {/* Add more core values cards here */}
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
