import React, { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import './SpecializedServicesPost.css';

// Import images for each specialized service
import lossPreventionImage from '../../Assets/images/lossPrevention.png';
import warehouseSecurityImage from '../../Assets/images/MarineTimeSecurity.png';
import executiveProtectionImage from '../../Assets/images/ArmedPoliceSecurity.png';

const SpecializedServices = () => {
  const [expandedService, setExpandedService] = useState('');

  // Function to toggle service details
  const toggleService = (service) => {
    setExpandedService(expandedService === service ? '' : service);
  };

  return (
    <div className="specializedServicesSection">
      <h2 className="specializedServicesTitle">SPECIALIZED SERVICES</h2>

      {/* Loss Prevention */}
      <div className="specializedService">
        <div className="serviceHeader" onClick={() => toggleService('Loss Prevention')}>
          <h3 className="serviceTitle">Loss Prevention</h3>
          <button className="expandButton">
            {expandedService === 'Loss Prevention' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Loss Prevention' && (
          <div className="serviceDetails active">
            <img src={lossPreventionImage} alt="Loss Prevention" className="serviceImage" />
            <p>
              You probably know your own business better than anyone, but it might prevent you from seeing where the risks lie. Our experts are trained to ask the right questions and spot problem areas, whether they are internal or external. When it comes to your store, construction site, plant, warehouse, or distribution center, protecting your inventory from loss is essential. Our loss prevention professionals help reduce your costs, prevent loss or internal fraud, and improve inventory management.
            </p>
          </div>
        )}
      </div>

      {/* Warehouse & Maritime Security */}
      <div className="specializedService">
        <div className="serviceHeader" onClick={() => toggleService('Warehouse & Maritime Security')}>
          <h3 className="serviceTitle">Warehouse & Maritime Security</h3>
          <button className="expandButton">
            {expandedService === 'Warehouse & Maritime Security' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Warehouse & Maritime Security' && (
          <div className="serviceDetails active">
            <img src={warehouseSecurityImage} alt="Warehouse & Maritime Security" className="serviceImage" />
            <p>
              Running a business which relies heavily on storing stock in warehouses comes with risks, especially if anything is of high-value. Whether your business has only one warehouse or numerous premises spread-out over different locations, our warehouse security guards can be deployed 24/7, 365 days of the year. Prevent unwanted intruders from accessing your warehouse with professional security guards who will maintain strict control over access points. Gatehouses and entrances will have manned security staff checking the identification of each person who enters the premise and will keep log books to record who is onsite and offsite at what times. With vandalism and theft unfortunately on the increase, the remote locations of warehouses and storage facilities often make them prime targets for criminal activity. Repay Security Services Ltd provide warehouse security services that are flexible to meet your needs from manned guarding, dog patrols, remotely monitored CCTV, and alarm installations that work to offer an enhanced warehouse security service that is second to none.
            </p>
          </div>
        )}
      </div>

      {/* Background Check/Undercover */}
      <div className="specializedService">
        <div className="serviceHeader" onClick={() => toggleService('Background Check/Undercover')}>
          <h3 className="serviceTitle">Background Check/Undercover</h3>
          <button className="expandButton">
            {expandedService === 'Background Check/Undercover' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Background Check/Undercover' && (
          <div className="serviceDetails active">
            {/* <img src={backgroundCheckImage} alt="Background Check/Undercover" className="serviceImage" /> */}
            <p>
              Background check helps to make sound decisions about people and organizations you do business with. This can include checks on key hiring you are about to do or also on business partners, be they individuals or corporates, with whom you plan to enter an agreement. REPAY security services Ltd performs the most discrete, comprehensive and detailed oriented background investigations possible. This includes, among others, criminal history, property and corporate ownership, tax records, bankruptcies, liens, judgments, resume & degree verification.
            </p>
          </div>
        )}
      </div>

      {/* Private Investigations */}
      <div className="specializedService">
        <div className="serviceHeader" onClick={() => toggleService('Private Investigations')}>
          <h3 className="serviceTitle">Private Investigations</h3>
          <button className="expandButton">
            {expandedService === 'Private Investigations' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Private Investigations' && (
          <div className="serviceDetails active">
            {/* <img src={privateInvestigationImage} alt="Private Investigations" className="serviceImage" /> */}
            <p>
              When you speak to our private investigators it will become clear to you that you are dealing with industry experts who work within the letter of the law and will not compromise our high level of morals and ethics simply for financial gain. Dealing with our private investigators will give you the best chance of the service you require being completed successfully the first time of asking. We have built our reputation on closing cases by obtaining evidence in the correct manner and always putting our clients’ needs first.
            </p>
          </div>
        )}
      </div>

      {/* Armed Executive Protection */}
      <div className="specializedService">
        <div className="serviceHeader" onClick={() => toggleService('Armed Executive Protection')}>
          <h3 className="serviceTitle">Armed Executive Protection</h3>
          <button className="expandButton">
            {expandedService === 'Armed Executive Protection' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Armed Executive Protection' && (
          <div className="serviceDetails active">
            <img src={executiveProtectionImage} alt="Armed Executive Protection" className="serviceImage" />
            <p>
              RSS Risk Management provides security transportation and executive protection services to a wide range of clients traveling to Nairobi and throughout Kenya. Our local, close-knit, and extremely experienced security team of protectors and security drivers have been working with RSS for multiple years. Whether just a secure transfer from airport to hotel for a business traveler, or a robust 24 hour armed security team to protect you or your family during a vacation, the RSS Nairobi based team are here to support.
            </p>
          </div>
        )}
      </div>

      {/* Add more specialized services here if needed */}
    </div>
  );
};

export default SpecializedServices;
