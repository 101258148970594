// App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css'; // Assuming this file contains global styles
import Navbar from './Components/NavbarSection/NavBar';
import NotFoundPage from './../src/Components/Pages/NotFoundPage/NotFoundPage';
import ContactBar from './Components/contactbar/Contactbar';
import Footer from './Components/footerSection/Footer';
import Home from './Components/Pages/Home';
import Preloader from './../src/Components/Preloader/Preloader'; 
import AboutUsPage from './Components/Pages/Aboutpage/AboutUsPage';
import PhysicalSecurity from './Components/ServicesPost/PhysicalSecurity';
import CCTVInstallation from './Components/ServicesPost/CCTVInstallationPost';
import AlarmMonitoring from './Components/ServicesPost/AlarmMonitoring';
import AccessControl from './Components/ServicesPost/AccessControlPost';
import BollardsTurnstilesBarriers from './Components/ServicesPost/BollardsTurnstilesBarriersPost';
import CanineServices from './Components/ServicesPost/CanineServicesPost';
import SpecializedServices from './Components/ServicesPost/SpecializedServicesPost';
import ElectricFenceSolutions from './Components/ServicesPost/ElectricFenceSolutionsPost';
import ContactUs from './Components/Pages/ContactUsPage/ContactUs';
import ServiceRequestForm from './Components/SurveyForm/ServiceRequestForm';

const App = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay with setTimeout
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000); // Simulating 3 seconds of loading time

        // Cleanup function to clear the timer
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {/* Conditionally render Preloader component based on loading state */}
            {loading ? (
                <Preloader />
            ) : (
                <>
                    {/* Render the main content of the website */}
                    <ContactBar />
                    <Navbar />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path='/about-rss' element={<AboutUsPage />} />

                        {/* Services Posts */}
                        <Route path='/services/physical-security' element={<PhysicalSecurity />} />
                        <Route path='/services/cctv-solutions' element={<CCTVInstallation />} />
                        <Route path='/services/alarm-monitoring-response' element={<AlarmMonitoring />} />
                        <Route path='/services/access-control' element={<AccessControl />} />
                        <Route path='/services/bollards-turnstiles-barriers' element={<BollardsTurnstilesBarriers />} />
                        <Route path='/services/security-dogs' element={<CanineServices />} />
                        <Route path='/services/specialized-services' element={<SpecializedServices />} />
                        <Route path='/services/electric-fence-solutions' element={<ElectricFenceSolutions />} />
                        <Route path='/contact-us' element={<ContactUs />} />
                        <Route path='/book-survey' element={<ServiceRequestForm />} />
                       
                        
                        {/* Always keep the NotFoundPage route at the end */}
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                    <Footer />
                </>
            )}
        </>
    );
}

export default App;
