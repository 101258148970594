import React from 'react';
import HeroSection from '../heroSection/hero';
import ServiceSection from '../ServiceSection/ServiceSection';
import WhoWeAreSection from '../WhoWeAreSection/WhoWeAreSection';
import WhyUs1 from './WhyUsSection/WhyUs1';

const Home = () => {


    return (
        <>
        
        <div className="content-container">
            <HeroSection />
            <WhoWeAreSection />
            <WhyUs1 />
            <ServiceSection />

        
        </div>
        </>
    );
}

export default Home;
