import React, { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import './CanineServices.css';

// Import images for each canine service
import protectionDogsImage from '../../Assets/images/dogsService.png';
import detectionDogsImage from '../../Assets/images/detectionDog.png';

const CanineServices = () => {
  const [expandedService, setExpandedService] = useState('');

  // Function to toggle service details
  const toggleService = (service) => {
    setExpandedService(expandedService === service ? '' : service);
  };

  return (
    <div className="canineServicesSection">
      <h2 className="canineServicesTitle">CANINE SERVICES</h2>
      <div className="canineServicesDescription">
        <p>
          From our operations center in Kenya, our canine business manages more than 900 staff members and more than 800 dogs in East Africa. Unlike many security companies in Africa who can provide canine units, we own all of our dogs and employ all of our handlers. This allows us to ensure the quality of our dogs, develop the best working relationship between our dogs and their handlers, and provide cost effective solutions to clients in the residential, commercial, agricultural, industrial, and NGO/diplomatic sectors. Our canine operations are held to the highest international operational standards.
          <br />
          <br />
          Our canine protection and detection services complement our other security products, such as manned guarding and response services.
        </p>
      </div>

      {/* Protection Dogs */}
      <div className="canineService">
        <div className="serviceHeader" onClick={() => toggleService('Protection Dogs')}>
          <h3 className="serviceTitle">Protection Dogs</h3>
          <button className="expandButton">
            {expandedService === 'Protection Dogs' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Protection Dogs' && (
          <div className="serviceDetails active">
            <img src={protectionDogsImage} alt="Protection Dogs" className="serviceImage" />
            <p>
              We have several breeds of dogs that are trained in intruder detection, searching, patrolling, and handler protection. The working abilities of all our dogs are constantly assessed, and all of our dogs and handlers are trained and certified in accordance with the standards set out by the National Association of Security Dog Users (NASDU).
            </p>
          </div>
        )}
      </div>

      {/* Detection Dogs */}
      <div className="canineService">
        <div className="serviceHeader" onClick={() => toggleService('Detection Dogs')}>
          <h3 className="serviceTitle">Detection Dogs</h3>
          <button className="expandButton">
            {expandedService === 'Detection Dogs' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Detection Dogs' && (
          <div className="serviceDetails active">
            <img src={detectionDogsImage} alt="Detection Dogs" className="serviceImage" />
            <p>
              At RSS Security, we have narcotic and explosive detection dogs trained to detect explosive material on people and in vehicles, compounds, buildings, and luggage. Dogs are trained to detect and indicate the presence of explosive material through body language, which the handler reads and begins necessary evacuation procedures.
              <br />
              <br />
              These search dogs, both passive and proactive, are ideal for detection at airports, ports, stadiums, arenas, shopping centers, nightclubs, and any locations where narcotics or explosives are a concern. We have well-trained dogs from many breeds for both narcotic and explosive detection.
            </p>
          </div>
        )}
      </div>

      {/* Add more canine services here if needed */}
    </div>
  );
};

export default CanineServices;
