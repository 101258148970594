import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './hero.css'; // Assuming this is your main CSS file for HeroSection
import slider1 from '../../Assets/images/securityGuard2.png';
import slider2 from '../../Assets/images/SecurityDog.png';
import slider4 from '../../Assets/images/CCTV-Security.png';
import slider3 from '../../Assets/newImages/securityde.jpg';
import slider5 from '../../Assets/newImages/surveilance.png';

const HeroSection = () => {
  const sliderSettings = {
    arrows: true,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    fade: false,
    cssEase: 'linear',
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <div className="slick-custom-arrow slick-next"></div>,
    prevArrow: <div className="slick-custom-arrow slick-prev"></div>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          speed: 300,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          speed: 300,
          arrows: true,
        },
      },
    ],
  };

  return (
    <div className="hero-section">
      <Slider {...sliderSettings}>
        {[
          {
            image: slider1,
            title: 'RSS (Repay Security Services Ltd).',
            subTitle: 'We Provide unmatched security solutions with trained professionals',
            linkTo:'/services/physical-security',
            buttonText: 'Physical Security',
          },
          {
            image: slider2,
            title: 'Canine Vigilance',
            subTitle: 'RSS, Where security meets loyalty. Our trained dogs ensure the highest level of protection..',
            linkTo: '/about-rss',
            buttonText: 'Read More',
          },
          {
            image: slider4,
            title: 'Specialized Safety.',
            subTitle: 'Tailored security services designed for your peace of mind.',
            linkTo:"/book-survey", // Change to your donation link
            buttonText: 'Book Survey',
          },
          {
            image: slider5,
            title: 'Surveillance Mastery.',
            subTitle: '24/7 monitoring, both on-site and off-site, ensuring your safety without compromise.',
            linkTo:"/services/cctv-solutions", // Change to your donation link
            buttonText: 'Read More',
          },
          {
            image: slider3,
            title: 'Total Security Solutions.',
            subTitle: 'We cover every aspect of your safety needs with professionalism and precision.',
            linkTo:"/book-survey", // Change to your donation link
            buttonText: 'How can we help you?',
          },
        ].map((item, index) => (
          <div key={index} className="slider-image">
            <img src={item.image} alt={`Slider ${index + 1}`} className="zoom-in" />
            <div className="overlay">
              <div className="hero-text">
                <h1>{item.title}</h1>
                <h1>
                  <span className="green-text">{item.subTitle}</span>
                </h1>
                <Link to={item.linkTo} className="hero-button2">
                  {item.buttonText}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HeroSection;
